<template>
  <div>
    <b-container>
      <b-card>
        <b-card-title>
          Información del usuario
        </b-card-title>
        <validation-observer
          ref="formValidation"
        >
          <b-form @submit.prevent="validateForm()">
            <b-row class="justify-content-center mb-2">
              <b-col
                lg="3"
                class="order-lg-2"
              >
                <base-cropper
                  :model="user"
                  :boundary-size="{ width: 320, height: 320 }"
                  :viewport-size="{ width: 300, height: 300 }"
                  @croppedImage="user.logo = $event"
                />
              </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center">
              <b-col sm="6">
                <b-form-group label="Nombre">
                  <validation-provider
                    #default="{ errors }"
                    name="Nombre"
                    rules="required"
                  >
                    <b-form-input
                      v-model="user.name"
                      placeholder="Nombre"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              class="d-flex justify-content-center"
            >
              <b-col sm="6">
                <b-form-group label="Email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="user.email"
                      placeholder="example@gmail.com"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center">
              <b-col
                sm="6"
              >
                <label class="form-control-label">Rol</label>
                <b-form-select
                  v-model="user.role_name"
                  :options="roles"
                  type="text"
                  required
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col class="d-flex justify-content-end mt-2">
                <b-button
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  Guardar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BCardTitle,
  BContainer,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import BaseCropper from '@core/components/BaseCropper.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BCardTitle,
    BContainer,
    BaseCropper,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  data() {
    return {
      email,
      required,
      user: {
        name: '',
        email: '',
        logo: null,
        role_name: '',
        role_resource_id: '',
      },
      activeStatusOptions: [
        {
          text: 'Activo',
          value: true,
        },
        {
          text: 'Inactivo',
          value: false,
        },
      ],
      roles: [
        {
          text: 'Administrador',
          value: 'admin',
        },
        {
          text: 'Cliente',
          value: 'customer',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
  },
  mounted() {
    if (this.$route.params.id) {
      this.getUser(this.$route.params.id)
        .then(() => {
          this.user = this.currentUser
        })
    }
  },
  methods: {
    ...mapActions('users', ['addUser', 'editUser', 'getUser']),
    validateForm() {
      if (this.$route.params.id) {
        this.$refs.formValidation.validate().then(success => {
          if (success) {
            this.editUser(this.user)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Usuario actualizada',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .then(() => {
                this.$router.push({ name: 'Categories' })
              })
          }
        })
      } else {
        this.$refs.formValidation.validate().then(success => {
          if (success) {
            this.addUser(this.user)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Usuario registrado',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .then(() => {
                this.$router.push({ name: 'Users' })
              })
          }
        })
      }
    },
  },
}
</script>

<style></style>
